<template>
    <v-card
        class="px-6 py-4"
    >
    <!-- Section: Introduction -->
    <v-row>
      <v-col>
        <h2>Introduction</h2>
        <br />
        <p><strong>Enigma Code Limited</strong>, trading as <strong>DOOH.COM</strong> ("we", "us", or "our"), respects your privacy and is committed to protecting your personal data. This privacy policy provides information on how we handle your personal data when you use our 'Deliver / Verify' Digital Out of Home Campaign Management and Reporting Tool, which is designed for facilitating collaboration among various stakeholders in the advertising and marketing industry.</p>
      </v-col>
    </v-row>
    <!-- Section: Important Information and Who We Are -->
    <v-row>
      <v-col>
        <h2>Important Information and Who We Are</h2>
        <br />
        <h3>Purpose of This Privacy Policy</h3>
        <br />
        <p>This privacy policy aims to give you information on how Enigma Code Limited collects and processes your personal data through your use of 'Deliver / Verify', particularly focusing on the limited data we collect: username, password, email address, job title, and company name.</p>
        <h3>Controller</h3>
        <br />
        <p>Enigma Code Limited is the controller and responsible for your personal data collected through the 'Deliver / Verify' platform.</p>
        <h3>Contact Details</h3>
        <br />
        <p>Our full details are:</p>
        <ul>
          <li>Full name of legal entity: Enigma Code Limited (Trading as DOOH.com )</li>
          <li>Email address: info@dooh.com</li>
          <li>Postal address: 26 Red Lion St, London WC1R 4HQ</li>
          <li>Telephone number: 0783 441 22 88</li>
        </ul>
        <p>You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues (<a href="https://www.ico.org.uk">www.ico.org.uk</a>). We would, however, appreciate the opportunity to address your concerns before you approach the ICO, so please contact us first.</p>
      </v-col>
    </v-row>
    <!-- Section: The Data We Collect About You -->
    <v-row>
      <v-col>
        <h2>The Data We Collect About You</h2>
        <br />
        <p>We collect a very limited amount of personal data, which includes:</p>
        <ul>
          <li>Identity Data: This includes your username and job title.</li>
          <li>Contact Data: This includes your email address and company name.</li>
        </ul>
        <p>We do not collect any Financial Data, as our platform does not process payments or handle financial transactions.</p>
      </v-col>
    </v-row>
    <!-- Section: How Is Your Personal Data Collected -->
    <v-row>
      <v-col>
        <h2>How Is Your Personal Data Collected?</h2>
        <br />
        <p>We collect data from and about you through direct interactions when you:</p>
        <ul>
          <li>Register to use 'Deliver / Verify'.</li>
          <li>Update or add additional details to your profile on our platform.</li>
          <li>Interact with other users within the platform.</li>
          <li>Interact, edit or update campaigns within the platform.</li>
        </ul>
      </v-col>
    </v-row>
    <!-- Section: How We Use Your Personal Data -->
    <v-row>
      <v-col>
        <h2>How We Use Your Personal Data</h2>
        <br />
        <p>We will only use your personal data for the purposes for which we collected it, which primarily includes providing you access to and functionality within the 'Deliver / Verify' platform. Specifically, we use your data to:</p>
        <ul>
          <li>Facilitate your use of the 'Deliver / Verify' platform.</li>
          <li>Enable communication and collaboration with other users.</li>
          <li>Send you alerts and updates about the platform, which are fully configurable by you.</li>
        </ul>
      </v-col>
    </v-row>

    <!-- Section: Data Extraction for Platform Improvement -->
    <v-row>
      <v-col>
        <h2>Data Extraction for Platform Improvement</h2>
        <br />
        <p>In our efforts to continuously improve our services, we may extract aggregated data from our system relating to user activities. This could include usage and activity statistics. It's important to note that this extraction would be solely for internal analysis to enhance our platform's functionality and overall user experience. No personal data identifying individual users would be used for these analyses unless explicitly agreed upon by the users.</p>
      </v-col>
    </v-row>

    <!-- Section: Use of Aggregated Usage Data for Marketing -->
    <v-row>
      <v-col>
        <h2>Use of Aggregated Usage Data for Marketing</h2>
        <br />
        <p>In our communications and marketing materials, we may use aggregated data to highlight the usage and reach of our 'Deliver / Verify' platform. Examples of such data include the total number of campaigns published, the number of artworks uploaded, and other similar statistics that reflect the engagement and activity on our platform.</p>
        <p>Please note that this aggregated data is purely statistical and does not include any personal data or identify individual users. The use of this data is intended to showcase the effectiveness and scale of our platform and services to prospective clients and partners.</p>
        <p>Our aim in sharing these statistics is to provide insights into the general usage trends of our platform, demonstrating its growth and impact in the advertising and marketing industry. This aggregated data helps us illustrate the capabilities and reach of our platform in a factual and non-intrusive manner.</p>
      </v-col>
    </v-row>

    <!-- Section: Use of Local Storage and Cookies -->
    <v-row>
      <v-col>
        <h2>Use of Local Storage and Cookies</h2>
        <br />
        <p>Our 'Deliver / Verify' platform utilises local storage and cookies, including analytics tools, to enhance user experience and understand platform usage better. These tools help us monitor user interactions and behaviours on our platform, which are essential for continually improving our services.</p>
      </v-col>
    </v-row>

    <!-- Section: Analytics Tools -->
    <v-row>
      <v-col>
        <h2>Analytics Tools</h2>
        <br />
        <p>We employ analytics tools to track user journeys and interactions on our platform. This includes gathering data on pages visited, session durations, and user engagement with various features. The insights gained assist us in optimising the platform's functionality and usability. All data collected through these tools is processed in a way that does not personally identify any individual user.</p>
      </v-col>
    </v-row>

    <!-- Section: Managing Cookies -->
    <v-row>
      <v-col>
        <h2>Managing Cookies</h2>
        <br />
        <p>You have full control over the cookies used on our platform. Through your browser settings, you can choose to block all cookies or specific ones. However, be advised that restricting cookies may affect some functionalities of our platform.</p>
      </v-col>
    </v-row>

    <!-- Section: Privacy and Consent -->
    <v-row>
      <v-col>
        <h2>Privacy and Consent</h2>
        <br />
        <p>We are committed to protecting your privacy and ensuring that all data collection via cookies and local storage complies with applicable data protection laws. Upon your first visit to our platform, you will be prompted to provide consent for the use of cookies. This consent can be withdrawn at any time by adjusting your browser settings.</p>
      </v-col>
    </v-row>

    <!-- Section: Disclosures of Your Personal Data -->
    <v-row>
      <v-col>
        <h2>Disclosures of Your Personal Data</h2>
        <br />
        <p>We do not share your personal data with external marketing or sales organisations. Any newsletters and platform alerts are fully under your control and can be configured in your user settings.</p>
      </v-col>
    </v-row>

    <!-- Section: Protection of Campaign Privacy -->
    <v-row>
      <v-col>
        <h2>Protection of Campaign Privacy</h2>
        <br />
        <p>We recognize the importance of privacy and confidentiality in the management of campaigns on our 'Deliver / Verify' platform. To this end, we have implemented robust measures to ensure that the details and activities of any campaign remain private and secure.</p>
        <h3>Access Control</h3>
        <p>Access to information about a specific campaign is strictly limited to system administrators of the Deliver / Verify platform and users who are explicitly invited to participate in or manage that campaign. This means that campaign information is not accessible to other users of the platform unless they have been granted permission.</p>
        <h3>Administrative Safeguards</h3>
        <p>Our platform's administrators are trained and committed to maintaining the highest level of confidentiality. They have access to campaign information only to the extent necessary for system maintenance and user support.</p>
        <h3>User Permissions</h3>
        <p>Users have full control over their campaign data. They can determine who is invited to view or edit their campaign information, ensuring that access is restricted to authorised individuals only.</p>
        <p>These practices are a part of our commitment to protect the privacy and integrity of campaign activities on our platform. We understand the sensitive nature of advertising and marketing campaigns and take considerable efforts to safeguard this information from unauthorised access or disclosure.</p>
      </v-col>
    </v-row>

    <!-- Section: Data Security -->
    <v-row>
      <v-col>
        <h2>Data Security</h2>
        <br />
        <p>We have implemented appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed.</p>
      </v-col>
    </v-row>

    <!-- Section: Data Retention -->
    <v-row>
      <v-col>
        <h2>Data Retention</h2>
        <br />
        <p>We will retain your personal data for as long as necessary to fulfil the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
      </v-col>
    </v-row>

    <!-- Section: Your Legal Rights -->
    <v-row>
      <v-col>
        <h2>Your Legal Rights</h2>
        <br />
        <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to request access, correction, erasure, or transfer of your personal data.</p>
        <p>If you wish to exercise any of the rights set out above, please contact us.</p>
      </v-col>
    </v-row>
    <!-- Section: Glossary -->
    <v-row>
      <v-col>
        <h2>Glossary</h2>
        <br />
        <p>This glossary is designed to clarify terms used in the privacy policy of Enigma Code Limited for the 'Deliver / Verify' platform. It is not exhaustive but covers the main concepts and terms relevant to our data processing activities as outlined in the policy.</p>
        <ul>
          <li><strong>Access Control:</strong> Measures or protocols implemented to regulate who can view or use resources in a computing environment. In the context of the 'Deliver / Verify' platform, this refers to the system that restricts access to campaign information, ensuring that only system administrators and specifically invited users can access certain data.</li>
          <li><strong>Administrative Safeguards:</strong> Administrative actions, policies, and procedures to manage the selection, development, implementation, and maintenance of security measures to protect electronic information, and to manage the conduct of the workforce in relation to the protection of that information. On our platform, this involves training and protocols that our administrators follow to maintain the confidentiality and integrity of campaign data.</li>
          <li><strong>Aggregated Data:</strong> This refers to data that has been compiled from multiple sources or records and presented in a summarized format. Aggregated data reflects broader trends or patterns and is processed in a way that individual users cannot be identified from it. In the context of this privacy policy, it includes statistics like the total number of campaigns published or artworks uploaded on our platform.</li>
          <li><strong>Analytics Tools:</strong> Software used to collect and analyze data about user behavior on our platform. These tools help in understanding how users interact with various features, thereby aiding in platform improvement.</li>
          <li><strong>Cookies:</strong> Small text files placed on a user's device by a website to collect standard internet log information and visitor behavior information. These are used on our platform to enhance user experience and analyze platform usage.</li>
          <li><strong>Data Controller:</strong> The entity (Enigma Code Limited in this case) that determines the purposes and means of processing personal data.</li>
          <li><strong>Data Erasure (Right to be Forgotten):</strong> The right of individuals to have their personal data deleted without undue delay in certain circumstances.</li>
          <li><strong>Data Portability:</strong> The right of individuals to receive their personal data in a structured, commonly used format and to have this data transferred to another data controller.</li>
          <li><strong>Data Processor:</strong> Any entity that processes data on behalf of the Data Controller. Includes parties handling data per Enigma Code Limited's instructions.</li>
          <li><strong>Data Protection Authority:</strong> The regulatory authority responsible for enforcing data protection laws. In the UK, this is the Information Commissioner's Office (ICO).</li>
          <li><strong>Data Subject:</strong> An individual whose personal data is being processed. In this policy, it refers to the users of the 'Deliver / Verify' platform.</li>
          <li><strong>Direct Interactions:</strong> Direct engagements between the data subject and the data controller, such as filling out forms or direct communication.</li>
          <li><strong>External Third Parties:</strong> Outside entities such as service providers, professional advisers, and public authorities who may process data on behalf of Enigma Code Limited.</li>
          <li><strong>Internal Third Parties:</strong> Entities within the Enigma Code Limited group that may process data under the company's direction.</li>
          <li><strong>Legitimate Interest:</strong> A basis for processing personal data that is necessary for the interests of Enigma Code Limited, as long as those interests do not infringe upon the rights and freedoms of data subjects.</li>
          <li><strong>Local Storage:</strong> A method by which data is stored locally on the user's device to improve website performance and save user preferences.</li>
          <li><strong>Marketing Purposes:</strong> Activities undertaken to promote and advertise services or products. This includes using data (such as aggregated usage statistics) to highlight the reach and effectiveness of a service or platform to potential customers or the public.</li>
          <li><strong>Personal Data:</strong> Information relating to an identified or identifiable individual. In the context of this policy, it includes details like username, email address, job title, and company name.</li>
          <li><strong>Processing:</strong> Operations performed on personal data, such as collection, recording, organization, structuring, storage, adaptation, alteration, retrieval, and analysis.</li>
          <li><strong>User Consent:</strong> The agreement by a user to allow the collection and processing of their data, including the use of cookies and local storage. Consent must be informed, freely given, and can be withdrawn at any time.</li>
          <li><strong>User Permissions:</strong> The rights and privileges assigned to users regarding access to information and decision-making capacities. On the 'Deliver / Verify' platform, this term refers to the control clients have over their campaign data, including the ability to invite or restrict other users from accessing their campaign information.</li>
        </ul>
        <br />
        <p>This glossary is designed to clarify terms used in the privacy policy of Enigma Code Limited for the 'Deliver / Verify' platform. It is not exhaustive but covers the main concepts and terms relevant to our data processing activities as outlined in the policy.</p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

export default {
    name: 'PrivacyPolicy',
    data: () => ({
    }),
}
</script>